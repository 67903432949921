import _ from 'lodash';
import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { cleanTitle } from '../../../../utils/postUtils';

import styles from './recentPostSection.module.scss';

const RecentPostSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(sort: { fields: [date], order: DESC }, limit: 4) {
            edges {
              node {
                id
                slug
                title
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allWordpressPost.edges;
        const recentPosts = _.map(posts, ({ node }) => {
          const { id, slug, title } = node;
          return (
            <div key={id} className={styles.row}>
              <div className={styles.rowContent}>
                <Link to={`/blog/${slug}`}>
                  <p dangerouslySetInnerHTML={{ __html: cleanTitle(title) }} />
                </Link>
              </div>
            </div>
          );
        });
        return <div>{recentPosts}</div>;
      }}
    />
  );
};

export default RecentPostSection;
